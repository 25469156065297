import React from 'react';
import {Link as RLink} from 'react-router-dom';
import {SetState} from '../utils/types/Global';

interface LinkType {
    to: string;
    children: React.ReactNode;
    setPointer: SetState<boolean>;
    [x: string]: any;
}

export const Link: React.FC<LinkType> = ({setPointer, to, children, ...props}) => {
    return (
        <RLink
            onMouseEnter={() => {
                setPointer(true);
            }}
            onMouseLeave={() => {
                setPointer(false);
            }}
            to={to}
            {...props}>
            {children}
        </RLink>
    );
};

interface ExternalLinkType extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    setPointer: SetState<boolean>;
}

export const ExternalLink: React.FC<ExternalLinkType> = ({setPointer, children, ...props}) => {
    return (
        <a
            onMouseEnter={() => {
                setPointer(true);
            }}
            onMouseLeave={() => {
                setPointer(false);
            }}
            {...props}>
            {children}
        </a>
    );
};
