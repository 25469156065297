import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import {Table, Split} from '../../components/Table';
import '../../assets/scss/partials/_contact.scss';
import {SectionType} from '../../utils/types/Pages';
import {ExternalLink} from '../../components/Link';
import {SetState} from '../../utils/types/Global';

interface ContactType extends SectionType {
    setPointer: SetState<boolean>;
}

const Contact: React.FC<ContactType> = ({setPointer, anchor}) => {
    return (
        <Section anchor={anchor} id="contact" className="contact-section">
            <Padding>
                <Table>
                    <Split type="row">
                        <h2>Contact</h2>
                    </Split>
                    <Split>
                        <ExternalLink setPointer={setPointer} href="mailto:hello@messinismarios.com?subject=General Inquiry" rel="noreferrer" target="_blank">
                            <p className="pre contact-link">
                                hello@{'\n'}
                                messinismarios.{'\n'}
                                com
                            </p>
                        </ExternalLink>
                    </Split>
                    <Split>
                        <p className="pre txtal-r">
                            +30 693{'\n'}
                            911 6640
                        </p>
                    </Split>
                    <Split className="pc" />
                    <Split className="pc" type="big">
                        <p className="pre">made with react</p>
                    </Split>
                    <Split className="pc">
                        <p className="pre txtal-r">© messinismarios {new Date().getFullYear()}</p>
                    </Split>
                    <Split className="mobile last">
                        <p className="pre txtal-c">© messinismarios {new Date().getFullYear()}</p>
                        <p className="pre txtal-c">made with react</p>
                    </Split>
                </Table>
            </Padding>
        </Section>
    );
};

export default Contact;
