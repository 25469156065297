import React, {MutableRefObject, useRef, useState, useEffect} from 'react';
// import loadable from '@loadable/component';

import '../assets/scss/pages/_project.scss';

import Layout from '../utils/Layout';

import Scroll from '../containers/partials/Scroll';
import ProjectCarousel from '../containers/partials/project/ProjectCarousel';
import {useParams} from 'react-router-dom';
import {ProjectParams} from '../utils/types/Pages';
import Section from '../containers/partials/Section';
import ProjectButtons from '../containers/partials/project/ProjectButtons';
import {DataType} from '../utils/data/Works';
import {Button} from '../components/Button';
import useWindowSize from '../utils/hooks/useWindowSize';
import {ProgressiveImage} from '../components/Image';
import {Behance, Dribbble, LinkedIn} from '../assets/images';
import {SetState} from '../utils/types/Global';
import {ExternalLink} from '../components/Link';
import {Helmet} from 'react-helmet-async';
import {motion} from 'framer-motion';
import {pageTransition} from '../utils/data/Global';
import Overlay from '../containers/partials/Overlay';
import Popup from '../containers/partials/Popup';

type ProjectType = {
    data: DataType;
    pointer: boolean;
    setPointer: SetState<boolean>;
    setWorkScroll: SetState<boolean>;
};

const Project: React.FC<ProjectType> = ({data, pointer, setPointer, setWorkScroll}) => {
    const windowSize = useWindowSize();
    const params = useParams<ProjectParams>();
    const project = params.id;
    const carouselRef = useRef() as MutableRefObject<HTMLDivElement>;
    const [active, setActive] = useState(0);
    const [maxWidth, setMaxWidth] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const maxScroll = maxWidth - (windowSize.width * 88) / 100;
    const [leaving, setLeaving] = useState(false);
    const [popup, setPopup] = useState(false);
    const [popupImage, setPopupImage] = useState<string | null>(null);

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (data.loading === false && data.data) {
            setLoaded(true);
        }
    }, [data]);

    useEffect(() => {
        setCarouselWidth();
    }, [windowSize.height, windowSize.width]);

    const setCarouselWidth = () => {
        setMaxWidth(carouselRef.current.clientWidth);
    };

    const setScroll = (i: number) => {
        setActive(i);
        let scroll: number = 0;
        if (i > 0) {
            scroll =
                (windowSize.width * 60) / 100 +
                (windowSize.width * ((i + 1) * 0.8)) / 100 +
                (windowSize.height * ((i - 1) * 63)) / 100 -
                ((windowSize.width * 88) / 100 - (windowSize.height * 63) / 100);
        }
        carouselRef.current.style.transform = `translateX(-${scroll}px)`;
    };

    let value = data?.data !== null ? data?.data.find((o: any) => o?.attributes?.Label === project) : null;

    if (project) {
        const sub = value?.attributes?.Subtitle.replace('\n', ' ');
        const title = value?.attributes?.Title + ' (' + sub + ') - Marios Messinis';
        const img = process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.xlarge?.url;
        const desc = value?.attributes?.Title + ' / ' + value?.attributes?.Subtitle + '. ' + value?.attributes?.Intro;
        return (
            <>
                <Overlay pointer={pointer} setPointer={setPointer} setLeaving={setLeaving} setWorkScroll={setWorkScroll} />
                <Popup popup={popup} setPopup={setPopup} popupImage={popupImage} setPopupImage={setPopupImage} />
                <Layout>
                    {loaded ? (
                        <Helmet>
                            <title>{title}</title>
                            <meta name="og:title" content={title} />
                            <meta name="twitter:title" content={title} />
                            <meta name="og:url" content={'https://messinismarios.com/project/' + project} />
                            <meta name="og:image" content={img} />
                            <meta name="twitter:image:src" content={img} />
                            <meta name="image" content={img} />
                            <meta name="description" content={desc} />
                            <meta name="og:description" content={desc} />
                            <meta name="twitter:description" content={desc} />
                            <link rel="preload" as="image" href={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.thumbnail?.url} />
                            {windowSize.width > 1200 ? (
                                <link rel="preload" as="image" href={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.xlarge?.url} />
                            ) : (
                                <link rel="preload" as="image" href={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.large?.url} />
                            )}
                        </Helmet>
                    ) : (
                        <Helmet>
                            <link rel="preconnect" href="https://api.messinismarios.com" />
                        </Helmet>
                    )}
                    <Scroll id="project-page" setPointer={setPointer} pointer={pointer} leaving={leaving}>
                        <>
                            <Section className="project-hero">
                                {loaded && windowSize.width <= 1200 && (
                                    <div className="project-preview">
                                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={pageTransition} className="ease">
                                            <ProgressiveImage
                                                src={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.large?.url}
                                                thu={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.thumbnail?.url}
                                                alt={project + '-img0'}
                                                className="ease"
                                            />
                                        </motion.div>
                                    </div>
                                )}
                                <ProjectButtons
                                    setPopupImage={setPopupImage}
                                    setPopup={setPopup}
                                    value={value}
                                    setPointer={setPointer}
                                    project={project}
                                    carouselRef={carouselRef}
                                    setScroll={setScroll}
                                    active={active}
                                />
                                <ProjectCarousel
                                    setPopupImage={setPopupImage}
                                    setPopup={setPopup}
                                    value={value}
                                    setPointer={setPointer}
                                    project={project}
                                    carouselRef={carouselRef}
                                    setScroll={setScroll}
                                    active={active}
                                />
                                {loaded && (
                                    <Section className="project-info">
                                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={pageTransition} className="project-title">
                                            <h2 className="project-subtitle">{value?.attributes?.Subtitle} ⏤</h2>
                                            <h1 className="project-name">{value?.attributes?.Title}</h1>
                                        </motion.div>
                                        <motion.p initial={{opacity: 0}} animate={{opacity: 1}} transition={pageTransition} className="project-description">
                                            {value?.attributes?.Intro}
                                        </motion.p>
                                        <motion.ul initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={pageTransition} className="socials">
                                            <li className="dr">
                                                <ExternalLink
                                                    setPointer={setPointer}
                                                    href={'https://dribbble.com/messinismarios'}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    aria-label="Link to my Dribbble profile">
                                                    <Dribbble />
                                                </ExternalLink>
                                            </li>
                                            <li className="be">
                                                <ExternalLink
                                                    setPointer={setPointer}
                                                    href={'https://www.behance.net/messinismarios'}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    aria-label="Link to my Behance profile">
                                                    <Behance />
                                                </ExternalLink>
                                            </li>
                                            <li className="ln">
                                                <ExternalLink
                                                    setPointer={setPointer}
                                                    href={'https://www.linkedin.com/in/messinismarios/'}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    aria-label="Link to my LinkedIn profile">
                                                    <LinkedIn />
                                                </ExternalLink>
                                            </li>
                                        </motion.ul>
                                    </Section>
                                )}
                            </Section>
                            {loaded && (
                                <Section className="project-details">
                                    <div className="project-details-image pc">
                                        {value?.attributes?.Images?.data.map((images: any, index: number) => {
                                            return (
                                                <ProgressiveImage
                                                    key={index}
                                                    src={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.url}
                                                    thu={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.formats?.thumbnail?.url}
                                                    alt="..."
                                                />
                                            );
                                        })}
                                    </div>

                                    <div className="project-details-text">
                                        <div className="mobile-carousel ease-carousel">
                                            {value?.attributes?.Carousel?.data.map((images: any, index: number) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            setPopup(true);
                                                            setPopupImage(process.env.REACT_APP_UPLOAD_URL + images?.attributes?.url);
                                                        }}
                                                        className={'carousel-img view'}>
                                                        <ProgressiveImage
                                                            src={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.formats?.small?.url}
                                                            thu={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.formats?.thumbnail?.url}
                                                            alt={project + '-img' + index}
                                                            className="ease"
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {value?.attributes?.Stats?.length > 0 && (
                                            <div className={value?.attributes?.Stats.length < 3 ? 'project-stats less' : 'project-stats'}>
                                                {value?.attributes?.Stats.map((stat: any, index: number) => {
                                                    return (
                                                        <div className="project-stat" key={'stat-' + index}>
                                                            <div className="stat-title">{stat.Title}</div>
                                                            <div className="stat-info">{stat.Info}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {value?.attributes?.Details.map((detail: any, index: number) => {
                                            return (
                                                <div className="project-detail" key={'info-' + index}>
                                                    <h2>0{index + 1}</h2>
                                                    <p>{detail.Text}</p>
                                                </div>
                                            );
                                        })}
                                        <a
                                            onMouseEnter={() => setPointer(true)}
                                            onMouseLeave={() => setPointer(false)}
                                            href="mailto:hello@messinismarios.com?subject=Quote Inquiry"
                                            rel="noreferrer"
                                            target="_blank">
                                            <Button type="quote" />
                                        </a>
                                    </div>
                                    <div className="project-details-image mobile">
                                        {value?.attributes?.Images?.data?.map((images: any, index: number) => {
                                            return (
                                                <ProgressiveImage
                                                    key={index}
                                                    src={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.url}
                                                    thu={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.formats?.thumbnail?.url}
                                                    alt="..."
                                                />
                                            );
                                        })}
                                    </div>
                                </Section>
                            )}
                        </>
                    </Scroll>
                </Layout>
            </>
        );
    }
    return null;
};

export default Project;
