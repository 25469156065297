import React from 'react';

type TableType = {
    children: React.ReactNode;
    className?: string;
    id?: string;
};

type SplitType = {
    children?: React.ReactNode;
    className?: string;
    id?: string;
    type?: 'bigger' | 'big' | 'small' | 'row';
};

export const Table: React.FC<TableType> = ({children, id = '', className = ''}) => {
    return (
        <div id={id} className={'table ' + className}>
            {children}
        </div>
    );
};

export const Split: React.FC<SplitType> = ({children, id = '', className = '', type = ''}) => {
    return (
        <div id={id} className={'slice ' + type + ' ' + className}>
            {children}
        </div>
    );
};
