import React from 'react';
import {ProjectCarouselType} from '../../../utils/types/Partials';
import {motion} from 'framer-motion';
import {pageTransition} from '../../../utils/data/Global';
import {ProgressiveImage} from '../../../components/Image';

const ProjectCarousel: React.FC<ProjectCarouselType> = ({setPopup, setPopupImage, value, project, carouselRef}) => {
    return (
        <div id="project-carousel" className="pc">
            <div className="carousel-wrapper ease-carousel" ref={carouselRef}>
                {value?.attributes?.Carousel?.data.map((images: any, index: number) => {
                    if (index > 0) {
                        return (
                            <div
                                onClick={() => {
                                    setPopup(true);
                                    setPopupImage(process.env.REACT_APP_UPLOAD_URL + images?.attributes?.url);
                                }}
                                className={'carousel-img view'}
                                key={'carousel-img' + index}>
                                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={pageTransition} className="motion ease">
                                    <ProgressiveImage
                                        src={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.formats?.large?.url}
                                        thu={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.formats?.thumbnail?.url}
                                        alt={project + '-img' + index}
                                        className={'ease'}
                                    />
                                </motion.div>
                            </div>
                        );
                    }

                    return (
                        <div
                            onClick={() => {
                                setPopup(true);
                                setPopupImage(process.env.REACT_APP_UPLOAD_URL + images?.attributes?.url);
                            }}
                            className={'carousel-img'}
                            key={'carousel-img' + index}>
                            <ProgressiveImage
                                src={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.formats?.xlarge?.url}
                                thu={process.env.REACT_APP_UPLOAD_URL + images?.attributes?.formats?.thumbnail?.url}
                                alt={project + '-img0'}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProjectCarousel;
