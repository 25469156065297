import React, {useEffect, useState} from 'react';
// import loadable from '@loadable/component';

import Wrapper from '../containers/partials/Wrapper';
import Layout from '../utils/Layout';

import Intro from '../containers/sections/Intro';
import About from '../containers/sections/About';
import Work from '../containers/sections/Work';
import Contact from '../containers/sections/Contact';
import Scroll from '../containers/partials/Scroll';
import Overlay from '../containers/partials/Overlay';
import {AnchorType} from '../utils/types/Pages';
import {SetState} from '../utils/types/Global';
import {Helmet} from 'react-helmet-async';
import {motion} from 'framer-motion';
import {pageTransitionSlow} from '../utils/data/Global';
import useWindowSize from '../utils/hooks/useWindowSize';
import {DataType} from '../utils/data/Works';
import {ProgressiveImage} from '../components/Image';
import {Hero, HeroM, HeroT} from '../assets/images';

// const Intro = loadable(() => import('../containers/sections/Intro'));
// const About = loadable(() => import('../containers/sections/About'));
// const Work = loadable(() => import('../containers/sections/Work'));
// const Contact = loadable(() => import('../containers/sections/Contact'));

interface HomeType extends AnchorType {
    data: DataType;
    pointer: boolean;
    setPointer: SetState<boolean>;
    workScroll: boolean;
    setWorkScroll: SetState<boolean>;
}

const Home: React.FC<HomeType> = ({data, pointer, anchors, setPointer, workScroll, setWorkScroll}) => {
    const [image, setImage] = useState('');
    const [hovered, setHovered] = useState(false);
    const windowSize = useWindowSize();
    const [leaving, setLeaving] = useState(false);

    const chooseImage = (image: string) => {
        if (!leaving) {
            setImage(image);
        }
    };

    const chooseHovered = (hovered: boolean) => {
        if (!leaving) {
            setHovered(hovered);
        }
    };

    useEffect(() => {
        if (workScroll) {
            window.scrollTo(0, anchors.work.current.offsetTop);
            setWorkScroll(false);
        }
    }, [anchors.work, workScroll, setWorkScroll]);

    const mainCursor = React.useRef<any>(null);
    const positionRef = React.useRef({
        mouseX: 0,
        mouseY: 0,
        destinationX: 0,
        destinationY: 0,
        distanceX: 0,
        distanceY: 0,
        key: -1,
    });

    React.useEffect(
        function setupListener() {
            function handleMouseMove(event: any) {
                const {clientX, clientY} = event;

                const mouseX = clientX;
                const mouseY = clientY;

                if (!leaving) {
                    positionRef.current.mouseX = mouseX - mainCursor.current.clientWidth / 2;
                    positionRef.current.mouseY = mouseY - mainCursor.current.clientHeight / 2;
                }
            }

            if (windowSize.width > 1200) {
                window.addEventListener('mousemove', handleMouseMove);
            }

            return function cleanupListener() {
                window.removeEventListener('mousemove', handleMouseMove);
            };
        },
        [leaving, windowSize.width],
    );

    useEffect(() => {
        if (windowSize.width <= 1200) {
            return;
        }
        const followMouse = () => {
            positionRef.current.key = requestAnimationFrame(followMouse);
            const {mouseX, mouseY, destinationX, destinationY, distanceX, distanceY} = positionRef.current;
            if (!destinationX || !destinationY) {
                positionRef.current.destinationX = mouseX;
                positionRef.current.destinationY = mouseY;
            } else {
                positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
                positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
                if (Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) < 0.1) {
                    positionRef.current.destinationX = mouseX;
                    positionRef.current.destinationY = mouseY;
                } else {
                    positionRef.current.destinationX += distanceX;
                    positionRef.current.destinationY += distanceY;
                }
            }

            if (mainCursor && mainCursor.current) {
                mainCursor.current.style.left = `${-(mainCursor.current.clientWidth / 3) + (destinationX - mainCursor.current.clientWidth / 3) / 6}px`;
                mainCursor.current.style.top = `${mainCursor.current.clientHeight / 4 + (destinationY - mainCursor.current.clientHeight / 3) / 2}px`;
            }
        };
        followMouse();
    }, [windowSize.width]);

    return (
        <>
            <Overlay pointer={pointer} setPointer={setPointer} anchors={anchors} setLeaving={setLeaving} setWorkScroll={setWorkScroll} />
            <Layout>
                <Helmet>
                    <title>Marios Messinis | Developer, Designer & Digital Artist</title>
                    <meta name="og:title" content="Marios Messinis | Developer, Designer & Digital Artist" />
                    <meta name="twitter:title" content="Marios Messinis | Developer, Designer & Digital Artist" />
                    <meta name="og:url" content="https://messinismarios.com" />
                    <meta name="og:image" content="https://messinismarios.com/assets/hero.jpg" />
                    <meta name="twitter:image:src" content="https://messinismarios.com/assets/hero.jpg" />
                    <meta name="image" content="https://messinismarios.com/assets/hero.jpg" />
                    <meta
                        name="description"
                        content="I am Marios, an athens-based developer and designer. I specialise in building advanced back-end applications, web platforms and websites, but also UI/UX, graphic designs and artwork."
                    />
                    <meta
                        name="og:description"
                        content="I am Marios, an athens-based developer and designer. I specialise in building advanced back-end applications, web platforms and websites, but also UI/UX, graphic designs and artwork."
                    />
                    <meta
                        name="twitter:description"
                        content="I am Marios, an athens-based developer and designer. I specialise in building advanced back-end applications, web platforms and websites, but also UI/UX, graphic designs and artwork."
                    />
                    {windowSize.width > 1200 ? <link rel="preload" as="image" href={Hero} /> : <link rel="preload" as="image" href={HeroM} />}
                    <link rel="preload" as="image" href={HeroT} />
                    <link rel="preconnect" href="https://api.messinismarios.com" />
                </Helmet>
                <Scroll id="home-page" pointer={pointer} setPointer={setPointer} anchors={anchors} leaving={leaving}>
                    <Wrapper>
                        <Intro />
                        <About anchor={anchors.about} />
                        <Work data={data} setPointer={setPointer} anchor={anchors.work} chooseImage={chooseImage} chooseHovered={chooseHovered} setLeaving={setLeaving} />
                        <Contact setPointer={setPointer} anchor={anchors.contact} />
                    </Wrapper>
                </Scroll>

                {windowSize.width > 1200 && (
                    <div className={leaving || hovered ? 'works-images pc works-hover ease-works' : 'works-images pc ease-works'}>
                        <motion.div
                            initial={{opacity: 0.6}}
                            exit={{opacity: 1, width: 0.6 * windowSize.width, height: 0.63 * windowSize.height, left: 0.12 * windowSize.width, top: 0.135 * windowSize.height}}
                            transition={pageTransitionSlow}
                            className="works-images-wr"
                            ref={mainCursor}>
                            {data?.data?.map((value: any, index: number) => {
                                let key = value?.attributes?.Label;
                                return (
                                    <div key={'works-images.li' + index} className={image === key ? 'works-image works-hover ease-works' : 'works-image ease-works'}>
                                        <ProgressiveImage
                                            width="800"
                                            height="800"
                                            alt={key}
                                            key={'works.' + index}
                                            src={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.xlarge?.url}
                                            thu={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.thumbnail?.url}
                                        />
                                        {/* <p className="works-number">21
                                    {index + 1}/{WorksLength}
                                </p> */}
                                    </div>
                                );
                            })}
                        </motion.div>
                    </div>
                )}
            </Layout>
        </>
    );
};

export default Home;
