import React from 'react';
import {Behance, Dribbble, LinkedIn} from '../../assets/images';
import {Link} from 'react-router-dom';
import {AnchorsType} from '../../utils/types/Pages';
import {motion} from 'framer-motion';
import {pageTransition} from '../../utils/data/Global';
// type PageType = 'project' | 'home';

interface OverlayType {
    anchors?: AnchorsType;
}

const MobileOverlay: React.FC<OverlayType> = () => {
    return (
        <div id="mobileoverlay" className="overlay mobile">
            <Link to={'/'}>
                <p>marios messinis.</p>
            </Link>
            <motion.ul initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={pageTransition} className="socials">
                <li className="dr">
                    <a href={'https://dribbble.com/messinismarios'} target="_blank" rel="noreferrer" aria-label="Link to my Dribbble profile">
                        <Dribbble />
                    </a>
                </li>
                <li className="be">
                    <a href={'https://www.behance.net/messinismarios'} target="_blank" rel="noreferrer" aria-label="Link to my Behance profile">
                        <Behance />
                    </a>
                </li>
                <li className="ln">
                    <a href={'https://www.linkedin.com/in/messinismarios/'} target="_blank" rel="noreferrer" aria-label="Link to my LinkedIn profile">
                        <LinkedIn />
                    </a>
                </li>
            </motion.ul>
        </div>
    );
};

export default MobileOverlay;
