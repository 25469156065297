import React, {useState, useEffect} from 'react';

interface ImageType extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    src: string;
    min?: string;
    thu?: string;
    alt: string;
    className?: string;
}

export const ProgressiveImage: React.FC<ImageType> = ({src, min, thu, alt, className = '', ...props}) => {
    const [imgSrc, setImgSrc] = useState(thu || min || src);
    useEffect(() => {
        const img = new Image();
        if (min) {
            img.src = min;
            img.onload = () => {
                setImgSrc(min);
            };
        }
    }, [min]);
    useEffect(() => {
        const img2 = new Image();
        img2.src = src;
        img2.onload = () => {
            setImgSrc(src);
        };
    }, [src]);
    return <img {...{src: imgSrc, ...props}} className={className + (min && imgSrc === src ? ' loaded' : ' loading')} alt={alt} />;
};
