import React from 'react';

type ButtonType = {
    type: 'quote' | 'contact';
};

const ButtonText = {
    quote: 'Get a Quote',
    contact: 'Contact',
};

export const Button: React.FC<ButtonType> = ({type}) => {
    return <div className={'btn ease ' + type + '-btn'}>{ButtonText[type]}</div>;
};
