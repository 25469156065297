import React from 'react';
import {Link} from '../components/Link';
import {SetState} from './types/Global';

type LayoutType = {
    children: React.ReactNode;
};

type PageLinkType = {
    link: string;
    children: React.ReactNode;
    setPointer: SetState<boolean>;
    setLeaving: SetState<boolean>;
    label?: string;
    click?: () => void;
};

const Layout: React.FC<LayoutType> = ({children}) => {
    return <React.Fragment>{children}</React.Fragment>;
};

// TODO FIX
export const PageLink: React.FC<PageLinkType> = ({label = undefined, setPointer, link, children, setLeaving, click}) => {
    return (
        <Link
            setPointer={setPointer}
            onClick={() => {
                setPointer(false);
                window.removeEventListener('mousemove', event => {});
                window.scrollTo(0, 0);
                setLeaving(true);
                click && click();
            }}
            aria-label={label}
            to={link}>
            {children}
        </Link>
    );
};

export default Layout;
