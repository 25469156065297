import React from 'react';
import {useLocation, matchPath} from 'react-router-dom';
import {PageLink} from '../../utils/Layout';
import {AnchorsType} from '../../utils/types/Pages';
import useWindowSize from '../../utils/hooks/useWindowSize';
import {ExternalLink} from '../../components/Link';
import {SetState} from '../../utils/types/Global';
import {Behance, Dribbble, LinkedIn} from '../../assets/images';
import {motion} from 'framer-motion';
// type PageType = 'project' | 'home';

interface OverlayType {
    pointer: boolean;
    setPointer: SetState<boolean>;
    anchors?: AnchorsType;
    setLeaving: SetState<boolean>;
    setWorkScroll: SetState<boolean>;
}

const Overlay: React.FC<OverlayType> = ({pointer, setPointer, anchors, setLeaving, setWorkScroll}) => {
    const location = useLocation();
    const windowSize = useWindowSize();
    return (
        <>
            <div id="overlay" className="overlay pc">
                {matchPath('/', location.pathname) ? (
                    <p
                        className="ease"
                        onClick={() => {
                            window.scrollTo(0, 0);
                        }}
                        onMouseEnter={() => {
                            setPointer(true);
                        }}
                        onMouseLeave={() => {
                            setPointer(false);
                        }}>
                        marios messinis.
                    </p>
                ) : (
                    <PageLink setLeaving={setLeaving} setPointer={setPointer} link={'/'}>
                        <p>marios messinis.</p>
                    </PageLink>
                )}
                <ul className="socials">
                    <li className="dr">
                        <ExternalLink
                            setPointer={setPointer}
                            href={'https://dribbble.com/messinismarios'}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Link to my Dribbble profile">
                            <Dribbble />
                        </ExternalLink>
                    </li>
                    <li className="be">
                        <ExternalLink
                            setPointer={setPointer}
                            href={'https://www.behance.net/messinismarios'}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Link to my Behance profile">
                            <Behance />
                        </ExternalLink>
                    </li>
                    <li className="ln">
                        <ExternalLink
                            setPointer={setPointer}
                            href={'https://www.linkedin.com/in/messinismarios/'}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Link to my LinkedIn profile">
                            <LinkedIn />
                        </ExternalLink>
                    </li>
                </ul>
                {/*  */}
                {matchPath('/project/:id', location.pathname) ? (
                    <motion.ul initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className="navbar">
                        <li className="ease">
                            <PageLink setLeaving={setLeaving} setPointer={setPointer} link={'/'} click={() => setWorkScroll(true)}>
                                ← work
                            </PageLink>
                        </li>
                    </motion.ul>
                ) : (
                    <motion.ul initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className="navbar">
                        <li
                            className="ease"
                            onMouseEnter={() => {
                                setPointer(true);
                            }}
                            onMouseLeave={() => {
                                setPointer(false);
                            }}
                            onClick={() => {
                                if (anchors) {
                                    window.scrollTo(0, anchors.about.current.offsetTop - windowSize.width / 50);
                                }
                            }}>
                            about
                        </li>
                        <li
                            className="ease"
                            onMouseEnter={() => {
                                setPointer(true);
                            }}
                            onMouseLeave={() => {
                                setPointer(false);
                            }}
                            onClick={() => {
                                if (anchors) {
                                    window.scrollTo(0, anchors.work.current.offsetTop);
                                }
                            }}>
                            work
                        </li>
                        <li
                            className="ease"
                            onMouseEnter={() => {
                                setPointer(true);
                            }}
                            onMouseLeave={() => {
                                setPointer(false);
                            }}
                            onClick={() => {
                                if (anchors) {
                                    window.scrollTo(0, anchors.contact.current.offsetTop);
                                }
                            }}>
                            contact
                        </li>
                    </motion.ul>
                )}
            </div>
        </>
    );
};

export default Overlay;
