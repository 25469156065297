// import React, {MutableRefObject, useRef} from 'react';
import React, {MutableRefObject, useCallback, useEffect, useMemo, useRef} from 'react';

import useWindowSize from '../../utils/hooks/useWindowSize';
import {SetState} from '../../utils/types/Global';
import {AnchorsType} from '../../utils/types/Pages';
import MobileOverlay from './MobileOverlay';
import {motion} from 'framer-motion';
import {pageTransitionDelay} from '../../utils/data/Global';

interface ScrollType {
    id: string;
    children: React.ReactNode;
    pointer: boolean;
    setPointer: SetState<boolean>;
    leaving: boolean;
    anchors?: AnchorsType;
}

const Scroll: React.FC<ScrollType> = ({id, children, anchors, leaving}) => {
    const windowSize = useWindowSize();
    const scrollingContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

    const noscroll = windowSize.width <= 1200;

    useEffect(() => {
        if (!noscroll && !leaving) {
            setBodyHeight();
        }
    }, [windowSize.height, windowSize.width, noscroll, leaving]);

    const setBodyHeight = () => {
        document.body.style.height = `${scrollingContainerRef.current.getBoundingClientRect().height}px`;
    };

    const data = useMemo(
        () => [
            {
                ease: 0.06,
                current: 0,
                previous: 0,
                rounded: 0,
            },
        ],
        [],
    );

    const smoothScrollingHandler = useCallback(() => {
        if (scrollingContainerRef?.current) {
            if (document.body.getBoundingClientRect().height !== scrollingContainerRef.current.getBoundingClientRect().height) {
                setBodyHeight();
            }

            // const scrollMax = scrollingContainerRef.current.getBoundingClientRect().height - windowSize.height;
            // const newScroll = window.scrollY * 1.5;

            data[0].current = window.scrollY;
            data[0].previous += (data[0].current - data[0].previous) * data[0].ease;
            data[0].rounded = Math.round(data[0].previous * 100) / 100;
            scrollingContainerRef.current.style.transform = `translate3d(0px, -${data[0].rounded}px, 0px)`;
        }

        requestAnimationFrame(() => smoothScrollingHandler());
    }, [data]);

    useEffect(() => {
        requestAnimationFrame(() => smoothScrollingHandler());
    }, [smoothScrollingHandler]);

    return (
        <motion.div className="scroll-html" transition={pageTransitionDelay} initial={{pointerEvents: 'none'}} exit={{pointerEvents: 'none'}} animate={{pointerEvents: 'all'}}>
            <div className="scroll-body">
                <div className={'scroll-wrapper ' + (noscroll ? 'noscroll' : '')}>
                    {/* <div className="scroll-wrapper"> */}
                    <div id={id} ref={!noscroll && !leaving ? scrollingContainerRef : null}>
                        <MobileOverlay anchors={anchors} />
                        {children}
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Scroll;
