import React, {useEffect} from 'react';
import Section from '../partials/Section';
import '../../assets/scss/partials/_intro.scss';
import gsap from 'gsap';
// import SplitText from 'gsap/SplitText';
import SplitText from '../../utils/helpers/SplitText3.min';
import {motion} from 'framer-motion';

function Intro() {
    useEffect(() => {
        const split = new SplitText('#intro-text', {
            type: 'lines',
            linesClass: 'lineChildren',
        });

        new SplitText('#intro-text', {
            type: 'lines',
            linesClass: 'lineParent',
        });

        gsap.to(split.lines, {
            duration: 1,
            y: 0,
            opacity: 1,
            stagger: 0.1,
            ease: 'power2',
        });
    }, []);

    return (
        <Section id="intro" className="intro-section">
            <motion.h1 initial={{opacity: 0}} animate={{opacity: 1}} id={'intro-text'}>
                <div>Developer</div>
                <div style={{left: '11vw'}}>Designer &</div>
                <div style={{left: '5vw'}}>Digital Artist</div>
            </motion.h1>
        </Section>
    );
}

export default Intro;
