import React, {useRef, MutableRefObject, useState, useEffect} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
// import loadable from '@loadable/component';

import Home from './pages/Home';
import Project from './pages/Project';
import {HelmetProvider} from 'react-helmet-async';
import useWindowSize from './utils/hooks/useWindowSize';
import useFetchData from './utils/hooks/useFetchData';

// const Home = loadable(() => import(/* webpackPrefetch: true */ './pages/Home'));
// const Project = loadable(() => import(/* webpackPrefetch: true */ './pages/Project'));

const App = () => {
    const AboutAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const ProjectsAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const ContactAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const Anchors = {about: AboutAnchor, work: ProjectsAnchor, contact: ContactAnchor};
    const [pointer, setPointer] = useState(false);
    const [workScroll, setWorkScroll] = useState(false);
    const location = useLocation();
    const windowSize = useWindowSize();

    const {data, loading, error} = useFetchData(`/works?populate=*&sort=Sort`);

    // Custom Cursor
    const mainCursor = React.useRef<any>(null);
    const followCursor = React.useRef<any>(null);
    const positionRef = React.useRef({
        mouseX: 0,
        mouseY: 0,
        destinationX: 0,
        destinationY: 0,
        distanceX: 0,
        distanceY: 0,
        key: -1,
    });

    React.useEffect(function setupListener() {
        function handleMouseMove(event: any) {
            const {clientX, clientY} = event;

            const mouseX = clientX;
            const mouseY = clientY;

            if (positionRef.current && mainCursor.current) {
                positionRef.current.mouseX = mouseX - followCursor.current.clientWidth / 2;
                positionRef.current.mouseY = mouseY - followCursor.current.clientHeight / 2;
                mainCursor.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
            }
        }

        if (windowSize.width > 1200) {
            window.addEventListener('mousemove', handleMouseMove);
        }

        return function cleanupListener() {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    });

    useEffect(() => {
        if (windowSize.width <= 1200) {
            return;
        }
        const followMouse = () => {
            positionRef.current.key = requestAnimationFrame(followMouse);
            const {mouseX, mouseY, destinationX, destinationY, distanceX, distanceY} = positionRef.current;
            if (!destinationX || !destinationY) {
                positionRef.current.destinationX = mouseX;
                positionRef.current.destinationY = mouseY;
            } else {
                positionRef.current.distanceX = (mouseX - destinationX) * 0.07;
                positionRef.current.distanceY = (mouseY - destinationY) * 0.07;
                if (Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) < 0.1) {
                    positionRef.current.destinationX = mouseX;
                    positionRef.current.destinationY = mouseY;
                } else {
                    positionRef.current.destinationX += distanceX;
                    positionRef.current.destinationY += distanceY;
                }
            }
            if (followCursor.current) {
                followCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
            }
        };
        followMouse();
    }, [windowSize.width]);
    return (
        <HelmetProvider>
            <div id="cursor" className={pointer ? 'm--hover pc' : 'pc'}>
                <div className="cursor --small" ref={mainCursor}></div>
                <div className="cursor --follow" ref={followCursor}></div>
            </div>
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.pathname}>
                    <Route path="/project/:id" element={<Project data={{data, loading, error}} pointer={pointer} setPointer={setPointer} setWorkScroll={setWorkScroll} />} />
                    <Route
                        path="*"
                        element={
                            <Home data={{data, loading, error}} anchors={Anchors} pointer={pointer} setPointer={setPointer} workScroll={workScroll} setWorkScroll={setWorkScroll} />
                        }
                    />
                </Routes>
            </AnimatePresence>
        </HelmetProvider>
    );
};

export default App;
