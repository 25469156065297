import React from 'react';
import {SetState} from '../../utils/types/Global';

interface PopupType {
    popup: boolean;
    setPopup: SetState<boolean>;
    popupImage: string | null;
    setPopupImage: SetState<string | null>;
}

const Popup: React.FC<PopupType> = ({popup, setPopup, setPopupImage, popupImage = null}) => {
    return (
        <div id="popup" className={popup ? 'ease opened' : 'ease'}>
            <div
                className="close ease"
                onClick={() => {
                    setPopup(false);
                    setPopupImage(null);
                }}>
                <div className="x">+</div>
            </div>
            {popupImage && <img className="ease" alt="Popup" src={popupImage} />}
            <div
                className="background"
                onClick={() => {
                    setPopup(false);
                    setPopupImage(null);
                }}></div>
        </div>
    );
};

export default Popup;
