import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import {Table, Split} from '../../components/Table';
import '../../assets/scss/partials/_about.scss';
import {SectionType} from '../../utils/types/Pages';
import {Hero, HeroM, HeroT} from '../../assets/images';
import {motion} from 'framer-motion';
import {pageTransition} from '../../utils/data/Global';
import {ProgressiveImage} from '../../components/Image';
import useWindowSize from '../../utils/hooks/useWindowSize';

const About: React.FC<SectionType> = ({anchor}) => {
    const windowSize = useWindowSize();
    return (
        <Section className="about-section">
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={pageTransition}>
                <ProgressiveImage src={windowSize.width > 1200 ? Hero : HeroM} thu={HeroT} alt={'Hero Image'} width={2000} height={1062} />
            </motion.div>
            <Padding id="about" anchor={anchor}>
                <Table>
                    <Split type="big">
                        <p>a few words ⏤</p>
                        <h2 className="pre">About{'\n'}Me</h2>
                    </Split>
                    <Split type="small">
                        <p>I am Marios, an Athens-based developer and designer.</p>
                        <p>
                            I specialise in building advanced back-end applications, multimedia web platforms and websites, but also user interfaces, graphic designs and digital
                            artwork.
                        </p>
                        <p>Looking into the future, I would like to break into mobile development, game design, and entrepreneurship.</p>
                    </Split>
                </Table>
            </Padding>
        </Section>
    );
};

export default About;
