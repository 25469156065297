import React from 'react';
import {ProjectCarouselType} from '../../../utils/types/Partials';
import {motion} from 'framer-motion';
import {pageTransition} from '../../../utils/data/Global';

const ProjectButtons: React.FC<ProjectCarouselType> = ({value, setPointer, project, setScroll, active}) => {
    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={pageTransition} id="carousel-buttons">
            {value?.attributes?.Carousel?.data.map((_value: any, index: number) => {
                return (
                    <div
                        className={index === active ? 'carousel-btn active' : 'carousel-btn'}
                        key={'carousel-btn' + index}
                        onMouseEnter={() => setPointer(true)}
                        onMouseLeave={() => setPointer(false)}
                        onClick={() => {
                            setScroll(index);
                        }}>
                        <div className="background"></div>
                    </div>
                );
            })}
        </motion.div>
    );
};

export default ProjectButtons;
