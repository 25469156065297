import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import {Table, Split} from '../../components/Table';
import '../../assets/scss/partials/_work.scss';
import {DataType} from '../../utils/data/Works';
import {SectionType} from '../../utils/types/Pages';
import {SetState} from '../../utils/types/Global';
import {PageLink} from '../../utils/Layout';

type WorkType = SectionType & {
    data: DataType;
    chooseImage: (image: string) => void;
    chooseHovered: (opacity: boolean) => void;
    setPointer: SetState<boolean>;
    setLeaving: SetState<boolean>;
};

const Work: React.FC<WorkType> = ({data, setPointer, chooseImage, chooseHovered, anchor, setLeaving}) => {
    return (
        <Section anchor={anchor} id="work" className="work-section relative">
            <div>
                <Padding>
                    <Table>
                        <Split type="small" />
                        <Split type="big" className="txtal-r">
                            <p>⏤ my latest work</p>
                            <h2>Portfolio</h2>
                            <ul onMouseEnter={() => chooseHovered(true)} onMouseLeave={() => chooseHovered(false)}>
                                {data?.data?.map((value: any, index: number) => {
                                    let key = value?.attributes?.Label;
                                    return (
                                        <li className="portfolio-link" key={'works.li' + index} onMouseEnter={() => chooseImage(key)}>
                                            <PageLink label={value?.attributes?.Title} setPointer={setPointer} link={'/project/' + key} setLeaving={setLeaving}>
                                                <img
                                                    src={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.thumbnail?.url}
                                                    width={value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.small?.width}
                                                    height={value?.attributes?.Carousel?.data?.[0]?.attributes?.formats?.small?.height}
                                                    alt={value?.attributes?.Title + ' Project Preview'}
                                                    className="mobile"
                                                />
                                            </PageLink>
                                            <PageLink setPointer={setPointer} link={'/project/' + key} setLeaving={setLeaving}>
                                                <h3 className="ease">
                                                    {value?.attributes?.Title}
                                                    <span>↗</span>
                                                </h3>
                                                <p className="pre pc ease">{value?.attributes?.Subtitle}</p>
                                            </PageLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Split>
                    </Table>
                </Padding>
            </div>
        </Section>
    );
};

export default Work;
